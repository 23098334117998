import React, { useState } from "react";

export const AppContext = React.createContext<{ auth: boolean; setAuth: (arg: boolean) => void }>({
    auth: false,
    setAuth: () => {},
});

const HerboproContext: React.FC<{ children: any }> = ({ children }) => {
    const [auth, setAuth] = useState<boolean>(false);

    return (
        <AppContext.Provider value={{ auth, setAuth }}>
            {children}
        </AppContext.Provider>
    );
};

export default HerboproContext;