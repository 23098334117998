import './App.css';
import styled from "styled-components";
import orangeStrip from "./resources/img/linea-naranja.svg";
import logo from "./resources/img/Logo.svg";
import MainPageLayout from './layouts/MainPageLayout.tsx';
import HerboproContext from './services/context.tsx';
import React from 'react';

const Container = styled.div`
  widht: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const StripeContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  width: 500px;
  padding: 0px 100px;
`;



function App() {
  return (
    <HerboproContext>
      <Container>
        <StripeContainer>
          <img
            style={{
              minWidth: "110vh",
              height: "60px",
              marginLeft: "-10px",
              marginTop: "-10px",
            }}
            alt='header'
            src={orangeStrip}
          />
        </StripeContainer>
        <LogoContainer>
          <img
            alt='herbopro'
            src={logo}
          />
        </LogoContainer>
        <Content>
          <MainPageLayout />
        </Content>
      </Container>
    </HerboproContext>
  );
}

export default App;
