import React, { useState, useEffect, useRef, useContext } from "react";
import { uploadFile, deleteFile } from "../services/files.tsx";
import styled from "styled-components";
import server from "../services/server.tsx";
import { ProductType } from "./Products/Products.d";
import { message, Switch } from "antd";
import { AppContext } from "../services/context.tsx";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 0px 100px;
    margin-bottom: 10px;
`;

const Input = styled.input`
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;
    border: 1px solid black;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 32px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: fit-content;
    &:disabled {
        border: none;
        background: #d3d3d3;
        color: grey;
        cursor: default;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #0d4d21;
`;

const Select = styled.select`
    width: 300px;
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;
    border: 1px solid black;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
`;

interface BuyProductType extends ProductType {
    quantity: number;
}

export const stateOptions = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
]

const Buy: React.FC = () => {
    const [products, setProducts] = useState<BuyProductType[]>([]);
    const [name, setName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [streetName, setStreetName] = useState<string>("");
    const [interiorNumber, setInteriorNumber] = useState<number>();
    const [exteriorNumber, setExteriorNumber] = useState<number>();
    const [neighborhood, setNeigborhood] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [receiptUrl, setReceiptUrl] = useState<string>("");
    const { auth } = useContext(AppContext);
    const inputRef = useRef<HTMLInputElement>(null);
    const stripe = useStripe();
    const elements = useElements();
    const [payWithCard, setPayWithCard] = useState<boolean>(false);
    const [receiptEmail, setReceiptEmail] = useState<string>("");

    const onUploadFile = async ({ target }: any) => {
        if (target.files && target.files.length > 0) {
            const file = target.files[0];
            let cleanName = file.name.replace(/[^a-zA-Z0-9.]/g, "");
            const fileUrl = await uploadFile(file, cleanName, "Orders");
            if (fileUrl.includes("Error|")) {
                message.error(fileUrl.replace("Error|", ""));
                return;
            }
            if (!!receiptUrl) {
                await deleteFile(receiptUrl);
            }
            setReceiptUrl(fileUrl);
        }   
    };

    useEffect(() => {
        (async () => {
            const res = await server("Products-gp");
            if (res.error_message) {
                message.error(res.error_message);
            } else {
                const products = res.data;
                setProducts(
                    products.map((product) => ({
                        ...product,
                        quantity: 0,
                    }))
                );
            }
        })();
    }, []);
    
    const validateCanSend =() => {
        return !!name && !!lastName && !!city && !!state && !!exteriorNumber
        && !!streetName && !!neighborhood && !!zipCode && (!!receiptUrl || payWithCard)
        && !!phoneNumber && !!products.find(({ quantity}) => quantity > 0)
    };

    const resetValues = () => {
        setProducts(
            products.map((product) => ({
                ...product,
                quantity: 0,
            }))
        );
        setName("");
        setLastName("");
        setCity("");
        setState("");
        setInteriorNumber(0);
        setExteriorNumber(0);
        setZipCode("");
        setNeigborhood("");
        setStreetName("");
        setPhoneNumber("");
        setReceiptUrl("");
        setReceiptEmail("");
        const cardElement = elements?.getElement(CardElement);
        cardElement?.clear();
    }

    const onSubmit = async () => {
        const cardElement = elements?.getElement(CardElement);
        let stripeId;
        if (cardElement && payWithCard) {
            const { paymentMethod, error } = await stripe?.createPaymentMethod({
                type: "card",
                card: cardElement,
            }) || {};
            if (error) {
                message.error(error.message);
                return;
            }
            stripeId = paymentMethod?.id;
        }
        const res = await server("Order-ro", {
            Products: products,
            Name: name,
            LastName: lastName,
            City: city,
            State: state,
            InteriorNumber: interiorNumber,
            ExteriorNumber: exteriorNumber,
            StreetName: streetName,
            Neighborhood: neighborhood,
            ZipCode: zipCode,
            PhoneNumber: phoneNumber,
            ReceiptUrl: receiptUrl,
            Auth: auth,
            StripeId: stripeId,
        });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            if (res.data && cardElement) {
                const { error } = await stripe?.confirmCardPayment(res.data, {
                    payment_method: stripeId,
                    receipt_email: receiptEmail,
                }) || {};
                if (error) {
                    message.error(error.message);
                    return;
                }
            }
            message.success(res.message);
            resetValues();
        }
    }

    return (
        <Container>
            <Title>Selecciona los productos que deseas</Title>
            {products.map((product, index) => (
                <Row key={product.id}>
                    <img
                        alt={product.name}
                        style={{
                            width: "100px",
                            height: "auto",
                        }}
                        src={product.image_url}
                    />
                    {product.name+" ($"+product.price+" MXN)"}
                    <Input
                        type="number"
                        onChange={(e) => {
                            let producstAux = [...products];
                            producstAux[index].quantity = Number(e.target.value);
                            setProducts(producstAux);
                        }}
                        style={{ width: "50px" }}
                        value={product.quantity}
                        min={0}
                        onFocus={(e) => e.target.select()}
                    />
                </Row>
            ))}
            {`Total: $${products.length > 0 ? products.map((product) => product.quantity * product.price).reduce((sum, val) => sum+val) : 0} MXN`}
            <Title>Datos de envío</Title>
            <Row style={{ gap: "20px" }}>
                <Input
                    style={{ width: "300px" }}
                    value={name}
                    placeholder="Nombre"
                    onChange={(e) => setName(e.target.value)}
                />
                <Input
                    style={{ width: "300px" }}
                    value={lastName}
                    placeholder="Apellido"
                    onChange={(e) => setLastName(e.target.value)}
                />
                <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Teléfono"
                    style={{ width: "300px" }}
                />
                <Input
                    value={streetName}
                    onChange={(e) => setStreetName(e.target.value)}
                    placeholder="Calle"
                    style={{ width: "300px" }}
                />
                <Input
                    value={interiorNumber}
                    onChange={(e) => setInteriorNumber(Number(e.target.value))}
                    placeholder="Número interior"
                    style={{ width: "100px" }}
                />
                <Input
                    value={exteriorNumber}
                    onChange={(e) => setExteriorNumber(Number(e.target.value))}
                    placeholder="Número exterior"
                    style={{ width: "100px" }}
                />
                <Input
                    value={neighborhood}
                    onChange={(e) => setNeigborhood(e.target.value)}
                    placeholder="Colonia"
                    style={{ width: "300px" }}
                />
                <Input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Municipio"
                    style={{ width: "300px" }}
                />
                <Select
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                >
                    <option value="" disabled>Estado</option>
                    {stateOptions.map((state) => 
                        <option value={state}>{state}</option>
                    )}
                </Select>
                <Input
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    placeholder="Código postal"
                    style={{ width: "100px" }}
                />
            </Row>
            <Title>Método de pago</Title>
            {!auth && (
                <Row>
                    Depositar y adjuntar recibo
                    <Switch
                        value={payWithCard}
                        onChange={setPayWithCard}
                    />
                    Pagar con tarjeta
                </Row>
            )}
            {!payWithCard ? (
                <>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        ref={inputRef}
                        onChange={onUploadFile}
                    />
                    {!auth && `
                        Puede depositar en BANAMEX en la cuenta 5204164960383393
                        o pagar en OXXO a BANAMEX 5204164960383393 y adjuntar su comprobante de pago
                    `}
                    <Row>
                        <Button onClick={() => inputRef?.current?.click()}>
                            Adjuntar comprobante de pago
                        </Button>
                        {receiptUrl && (
                            <img
                                alt="Recibo"
                                style={{
                                    width: "50px",
                                    height: "auto",
                                }}
                                src={receiptUrl}
                                onClick={() => window.open(receiptUrl)}
                            />
                        )}
                    </Row>
                </>
            ) : (
                <>
                    <Input
                        value={receiptEmail}
                        placeholder="Email para recibir recibo"
                        onChange={(e) => setReceiptEmail(e.target.value)}
                    />
                    <CardElement />
                </>
            )}
            <Button onClick={onSubmit} disabled={!validateCanSend()}>
                Enviar
            </Button>
        </Container>
    );
};

export default Buy;


