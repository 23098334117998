import React from "react";
import Modal from "../../components/Modal.tsx";
import styled from "styled-components";
import server from "../../services/server.tsx";
import { ProductType } from "./Products.d";
import { message } from "antd";
import { deleteFile } from "../../services/files.tsx";

interface ConfirmDeleteModalProps {
    closeModal: () => void;
    reload: () => void;
    product: ProductType;
}

const Button = styled.button`
    border-radius: 16px;
    height: 32px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: fit-content;
`;

const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    gap: 15px;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
    closeModal,
    reload,
    product,
}) => {
    const onSubmit = async () => {
        const res = await server("Products-dp", {
            Id: product.id
        });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            await deleteFile(product.image_url);
            message.success(res.message);
            reload();
            closeModal();
        }
    };

    return (
        <Modal open title="Eliminar producto" closeModal={closeModal}>
            <Content>
                <Container>
                    {`¿Estás seguro que deseas eliminar ${product.name}?`}
                    <ButtonRow>
                        <Button style={{ background: "white"}} onClick={closeModal}>
                            Cancelar
                        </Button>
                        <Button style={{ background: "red"}} onClick={onSubmit}>
                            Eliminar
                        </Button>
                    </ButtonRow>
                </Container>
            </Content>
        </Modal>
    );
};

export default ConfirmDeleteModal;
