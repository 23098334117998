import React from "react";
import styled from "styled-components";
import coffeeCouple from "../resources/img/coffee.jpg";
import doctorImg from "../resources/img/doctor.jpg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
`;

const GreenText = styled.div`
    width: 800px;
    font-weight: bold;
    color: #0d4d21;
    text-align: center;
    font-size: 20px;
`;

const Text = styled.div`
    width: 750px;
    text-align: center;
    color: black;
    font-weight: normal;
    font-size: 16px;
`;

const Start: React.FC = () => {
    return (
        <Container>
            <img
                alt="cofee"
                src={coffeeCouple}
                style={{ width: "fit-content", height: "400px" }}
            />
            <GreenText>
                Empresa 100% mexicana, localizada en Tlaquepaque, Jalisco fundada hace 15 años con la
                finalidad de mejorar la calidad de vida de las personas con padecimientos crónicos degenerativos.
            </GreenText>
            <Text>
                Nuestros productos han sido probados por cientos de personas, así como por
                medicos especialistas los cuales han ratificado los excelentes resultados
            </Text>
            <img
                alt="doctor"
                src={doctorImg}
                style={{ width: "fit-content", height: "400px" }}
            />
            <GreenText style={{ width: "750px" }}>
                Misión
                <Text>
                    Elaborar y distribuir productos de alta calidad que ayuden a mejorar el estado
                    de salud general de las personas.
                </Text>
            </GreenText>
            <GreenText style={{ width: "750px" }}>
                Visión
                <Text>
                    Ser una opción reconocida y confiable que ayude a mejorar la calidad de vida de las personas
                </Text>
            </GreenText>
        </Container>
    );
};

export default Start;
