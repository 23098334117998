import { S3Client, PutObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3";
import { s3Config } from "./constants.tsx";

//@ts-ignore
const client = new S3Client({
    region: s3Config.bucketRegion,
    credentials: {
        accessKeyId: s3Config.accessKey,
        secretAccessKey: s3Config.secretAccessKey,
    },
});

export const uploadFile = async (file: string, fileName: string, filePath: string) => {
    const key = `${filePath}/${fileName}`;
    try {
        await client.send(new PutObjectCommand({
            Bucket: s3Config.bucketName,
            Key: key,
            Body: file,
            ACL: "public-read",
        }));
    } catch(e) {
        console.log(e);
        return "Error|Se produjo un error al subir el archivo";
    }
    return `https://${s3Config.bucketName}.s3.${s3Config.bucketRegion}.amazonaws.com/${key}`;
}

export const deleteFile = async (filePath: string) => {
    try {
        await client.send(new DeleteObjectCommand({
            Bucket: s3Config.bucketName,
            Key: filePath.replace(`https://${s3Config.bucketName}.s3.${s3Config.bucketRegion}.amazonaws.com/`, ""),
        }))
    } catch {
        return "No se pudo eliminar el archivo";
    }
};