import React, { useState, useEffect, useContext } from "react";
import { message } from "antd";
import server from "../../services/server.tsx";
import styled from "styled-components";
import EditProductsModal from "./EditProductsModal.tsx";
import { ProductType } from "./Products.d";
import ReactMarkdown from "react-markdown";
import { MdEdit } from "react-icons/md";
import { AppContext } from "../../services/context.tsx";
import runningImage from "../../resources/img/run.jpg";
import { FaTrash } from "react-icons/fa";
import ConfirmDeleteModal from "./ConfirmDeleteModal.tsx";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 0px 100px;
    margin-bottom: 10px;
`;

const ProductContainer = styled.div`
    display: flex;
    flex: 1;
    flex: direction: row;
    gap: 20px;
`;

const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
`;

const ProductTitle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #0d4d21;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 40px;
    font-size: 16px;
    width: 200px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: 150px;
`;

const ProductSide = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const StyledMarkDown = styled(ReactMarkdown)`
    font-size: 18px;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 2px;
    &:hover{
        background-color: #d3d3d3;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
`;

const Title = styled.div`
    font-size: 30px;
    text-align: center;
    color: #b1c904;
    font-weight: bold;
    width: 600px;
`;

const defaultProduct = {
    id: 0,
    name: "",
    image_url: "",
    description: "",
    price: 0,
};

const Products: React.FC = () => {
    const [product, setProduct] = useState<ProductType | null>(null);
    const [proucts, setProducts] = useState<ProductType[]>([]);
    const [productToDelete, setProductToDelete] = useState<ProductType | null>(null);
    const { auth } = useContext(AppContext);
    const navigate = useNavigate();

    const getProducts = async () => {
        const res = await server("Products-gp");
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            setProducts(res.data);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <Container>
            {!!product && <EditProductsModal product={product} closeModal={() => setProduct(null)} reload={getProducts} />}
            {!!productToDelete && <ConfirmDeleteModal product={productToDelete} closeModal={() => setProductToDelete(null)} reload={getProducts}/>}
            {auth ? (
                <Button style={{ width: "fit-content"}} onClick={() => setProduct(defaultProduct)}>
                    Agregar producto
                </Button>
            ) : (
                <HeaderContainer>
                    <img
                        src={runningImage}
                        alt="run"
                        style={{
                            width: "40%",
                            height: "auto",
                        }}
                    />
                    <Title>
                        PRODUCTOS 100% NATURALES QUE MEJORAN TU CALIDAD DE VIDA
                    </Title>
                </HeaderContainer>
            )}
            {proucts.map((product) => (
                <ProductContainer key={product.id}>
                    <ProductSide>
                        <img
                            src={product.image_url}
                            style={{ width: "100%", height: "auto" }}
                            alt="product"
                        />
                        <Button onClick={() => navigate("/home/buy")}>Comprar</Button>
                    </ProductSide>
                    <ProductInfo>
                        <ProductTitle>
                            {product.name}
                            {auth && (
                                <>
                                    <IconContainer onClick={() => setProduct(product)}>
                                        <MdEdit size={20} />
                                    </IconContainer>
                                    <IconContainer onClick={() => setProductToDelete(product)}>
                                        <FaTrash size={16} color="red" />
                                    </IconContainer>
                                </>
                            )}
                        </ProductTitle>
                        <StyledMarkDown
                            children={product.description}
                        />
                    </ProductInfo>
                </ProductContainer>
            ))}
        </Container>
    );
};

export default Products;
