import React, { useEffect, useState } from "react";
import server from "../../services/server.tsx";
import { message } from "antd";

interface PaymentMethodProps {
    stripeId: string;
}

const PaymentMethodPopover: React.FC<PaymentMethodProps> = ({
    stripeId
}) => {
    const [last4, setLast4] = useState<number>();
    const [brand, setBrand] = useState<string>("");

    useEffect(() => {
        (async () => {
            const res = await server("Order-gpmd", { StripeId: stripeId });
            if (res.error_message) {
                message.error(res.error_message);
            } else {
                setLast4(res.data.card.last4);
                setBrand(res.data.card.brand);
            }
        })();
    }, [stripeId]);

    return (
        <div>
            <p>
                <b>Tipo de tarjeta:</b> {brand}
            </p>
            <p>
                <b>Últimos 4:</b> {last4}
            </p>
        </div>
    );
};

export default PaymentMethodPopover;
