import axios from "axios";
import { APP_BACK } from "./constants.tsx";

const event = new Event("remove", {"bubbles":true, "cancelable":false});

const transformRequest = (data) => {
    if (data && typeof data === "object") {
        let params = new FormData();
        Object.keys(data).forEach((key) => {
            if (typeof data[key] === "object" || Array.isArray(data[key])) {
                params.append(key, JSON.stringify(data[key]));
            } else {
                params.append(key, data[key]);
            }
        });
        return params;
    }
    return data;
}

const ServerInstance = axios.create({
    baseURL: APP_BACK,
    transformRequest,
});

const server: any = async (func: string, args?: any) => {
    try {
        const auth = localStorage.getItem("auth");
        let res = await ServerInstance.post("", { func, args }, { headers: { Authorization: `Bearer ${auth}`}});
        if (res.data.error_message) {
            if (res.data.error_message.includes("Unauthorized|")) {
                document.dispatchEvent(event);
                res.data.error_message = res.data.error_message.replace("Unauthorized|", "");
            }
        }
        return (res.data);
    } catch(e) {
        console.log(e);
        return {
            error_message: "Error de servidor",
        };
    }
}

export default server;