import React, { useState, useContext } from "react";
import styled from "styled-components";
import server from "../services/server.tsx";
import { message } from "antd";
import { sha256 } from "js-sha256";
import { AppContext } from "../services/context.tsx";
import { useNavigate } from "react-router-dom";

const Input = styled.input`
    width: 300px;
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    gap: 20px;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 32px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 10px;
`;

const Login: React.FC<{ register?: boolean }>= ({ register = false }) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const { setAuth } = useContext(AppContext);
    const navigate = useNavigate();

    const onSubmit = async () => {
        const res = await server("User-li", { email, password: sha256(password) });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            if (res.message){
                message.success(res.message);
            }
            localStorage.setItem("auth", res.data);
            setAuth(true);
            window.addEventListener("remove", () => {
                setAuth(false);
                localStorage.setItem("auth", "");
            });
            navigate("/home/orders");
        }
    }

    const onRegisterUser = async () => {
        const res = await server("User-ru", { email, password: sha256(password) });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            if (res.message){
                message.success(res.message);
            }
            setEmail("");
            setPassword("");
        }
    };

    return (
        <Container>
            <div style={{ display: "flex", flexDirection: "column" }}>
                Usuario
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                Contraseña
                <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            {register ? (
                <Button onClick={onRegisterUser}>
                    Registrar usuario
                </Button>
            ) : (
                <Button onClick={onSubmit}>
                    Inicar sesión
                </Button>
            )}
        </Container>
    );
};

export default Login;