import React, { useState } from "react";
import styled from "styled-components";
import { SupplierToEdit } from "./Suppliers.d";
import Modal from "../../components/Modal.tsx";
import server from "../../services/server.tsx";
import { message } from "antd";

interface EditSuppliersModalProps {
    closeModal: () => void;
    supplier: SupplierToEdit;
    reload: () => void;
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 32px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: fit-content;
`;

const Input = styled.input`
    width: 275px;
    border-radius: 6px;
    height: 30px;
    padding: 0 10px;
    border-color: black;
`;


const ButtonRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
`;

const EditSuppliersModal: React.FC<EditSuppliersModalProps> = ({
    supplier,
    closeModal,
    reload,
}) => {
    const [name, setName] = useState<string>(supplier.name);
    const [address, setAddress] = useState<string>(supplier.address);
    const [phoneNumber, setPhoneNumber] = useState<string>(supplier.phone_number);
    const [city, setCity] = useState<string>(supplier.city);
    const [state, setState] = useState<string>(supplier.state);

    const onSubmit = async () => {
        const res = await server("Suppliers-rs", {
            Name: name,
            Id: supplier.id,
            Address: address,
            PhoneNumber: phoneNumber,
            City: city,
            State: state,
        });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            message.success(res.message);
            reload();
            closeModal();
        }
    };

    return (
        <Modal
            open
            closeModal={closeModal}
            title="Editar distribuidor"
            width="700px"
        >
            <Content>
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre"
                />
                <Input
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Dirección"
                />
                <Input
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Número de teléfono"
                />
                <Input
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="Estado"
                />
                <Input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Municipio"
                />
                <ButtonRow>
                    <Button style={{ background: "white" }} onClick={closeModal}>
                        Cancelar
                    </Button>
                    <Button onClick={onSubmit}>
                        Guardar
                    </Button>
                </ButtonRow>
            </Content>
        </Modal>
    );
};

export default EditSuppliersModal;