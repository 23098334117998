import React, { useState, useEffect } from "react";
import { Table, DatePicker, message, Popover } from "antd";
import styled from "styled-components";
import server from "../../services/server.tsx";
import { stateOptions } from "../Buy.tsx";
import { ProductType } from "../Products/Products.d.tsx";
import PaymentMethodPopover from "./PaymentMethodPopover.tsx";


const Input = styled.input`
    width: 300px;
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 0px 100px;
`;

const PopoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 10px;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 32px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: fit-content;
`;

const ButtonRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
`;

const Select = styled.select`
    width: 300px;
    border-radius: 6px;
    height: 32px;
    padding: 0 10px;
    border: 1px solid black;
`;

interface Order {
    id: number;
    client_name: string;
    phone_number: string;
    client_address: string;
    city: string;
    state: string;
    date: string;
    delivered_date: string;
    products: string;
    total_price: string;
    receipt_url: string;
}

interface PopoverProps {
    children: React.ReactNode;
    onCancel: () => void;
    onConfirm: () => void;
}

const Filter: React.FC<PopoverProps> = ({
    children,
    onCancel,
    onConfirm
}) => (
    <PopoverContainer>
        {children}
        <ButtonRow>
            <Button style={{ background: "white" }} onClick={onCancel}>
                Limpiar
            </Button>
            <Button onClick={onConfirm}>
                Filtrar
            </Button>
        </ButtonRow>
    </PopoverContainer>
);

const Orders: React.FC = () => {
    const [dateDeliveredInital, setDateDelieveredInitial] = useState<string>("");
    const [dataDeliveredFinal, setDateDeliveredFinal] = useState<string>("");
    const [clientName, setClientName] = useState<string>("");
    const [clientPhoneNumber, setClientPhoneNumber] = useState<string>("");
    const [clientAddress, setClientAddress] = useState<string>("");
    const [clientCity, setClientCity] = useState<string>("");
    const [clientState, setClientState] = useState<string>("");
    const [productIds, setProductIds] = useState<string>("");
    const [orderDateInitial, setOrderDateInitial] = useState<string>("");
    const [orderDateFinal, setOrderDateFinal] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [orders, setOrders] = useState<Order[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [stripeId, setStripeId] = useState<string>("");

    const getOrders: (args?: any) => void = async ({
        DateDeliveredInital = dateDeliveredInital,
        DateDeliveredFinal = dataDeliveredFinal,
        ClientName = clientName,
        ClientPhoneNumber = clientPhoneNumber,
        ClientAddress = clientAddress,
        ClientCity = clientCity,
        ClientState = clientState,
        ProductIds = productIds,
        OrderDateInitial = orderDateInitial,
        OrderDateFinal = orderDateFinal,
        CurrentPage = currentPage,
        PerPage = perPage,
    } = {}) => {
        const res = await server("Order-gos", {
            DateDeliveredInital,
            DateDeliveredFinal,
            ClientName,
            ClientPhoneNumber,
            ClientAddress,
            ClientCity,
            ClientState,
            ProductIds,
            OrderDateFinal,
            OrderDateInitial,
            CurrentPage,
            PerPage,
        });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            setOrders(res.data.Orders);
            setTotalOrders(res.data.TotalOrders);
        }
    };

    const updateOrderDeliveredDate = async (Id: number, DeliveredDate: string) => {
        const res = await server("Order-uodd", {
            Id,
            DeliveredDate,
        });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            message.success(res.message);
            getOrders();
        }
    };

    useEffect(() => {
        getOrders();
        (async () => {
            const res = await server("Products-gp");
            if (!res.error_message) {
                setProducts(res.data);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "Nombre del cliente",
            dataIndex: 'client_name',
            key: 'client_name',
            ellipsis: true,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setClientName("");
                        getOrders({ ClientName: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar nombre del cliente
                    <Input
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        placeholder="Nombre del cliente..."
                    />
                </Filter>
            ),
        },
        {
            title: "Teléfono",
            dataIndex: 'phone_number',
            key: 'phone_number',
            ellipsis: true,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setClientPhoneNumber("");
                        getOrders({ ClientPhoneNumber: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar teléfono del cliente
                    <Input
                        value={clientPhoneNumber}
                        onChange={(e) => setClientPhoneNumber(e.target.value)}
                        placeholder="Teléfono del cliente..."
                    />
                </Filter>
            ),
        },
        {
            title: "Dirección",
            dataIndex: 'client_address',
            key: 'client_address',
            ellipsis: false,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setClientAddress("");
                        getOrders({ ClientAddress: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar dirección del cliente
                    <Input
                        value={clientAddress}
                        onChange={(e) => setClientAddress(e.target.value)}
                        placeholder="Dirección del cliente..."
                    />
                </Filter>
            ),
        },
        {
            title: "Municipio",
            dataIndex: 'city',
            key: 'city',
            ellipsis: true,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setClientCity("");
                        getOrders({ ClientCity: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar municipio del cliente
                    <Input
                        value={clientCity}
                        onChange={(e) => setClientCity(e.target.value)}
                        placeholder="Municipio del cliente..."
                    />
                </Filter>
            )
        },
        {
            title: "Estado",
            dataIndex: 'state',
            key: 'state',
            ellipsis: true,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setClientState("");
                        getOrders({ ClientState: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar Estado
                    <Select
                        value={clientState}
                        onChange={(e) => setClientState(e.target.value)}
                    >
                        <option value="" disabled>Estado</option>
                        {stateOptions.map((state) => 
                            <option value={state}>{state}</option>
                        )}
                    </Select>
                </Filter>
            )
        },
        {
            title: "Fecha",
            dataIndex: 'date',
            key: 'date',
            ellipsis: true,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setOrderDateInitial("");
                        setOrderDateFinal("");
                        getOrders({ OrderDateInitial: "", OrderDateFinal: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar fecha de orden
                    <div
                        style={{
                            flexDirection: "row",
                            width: "100%",
                            display: "flex",
                            gap: "10px"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            Después de
                            <DatePicker
                                value={orderDateInitial}
                                onChange={setOrderDateInitial}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            Antes de
                            <DatePicker
                                value={orderDateFinal}
                                onChange={setOrderDateFinal}
                            />
                        </div>
                    </div>
                    
                </Filter>
            )
        },
        {
            title: "Fecha de entrega",
            dataIndex: 'date_delivered',
            key: 'date_delivered',
            editable: true,
            ellipsis: true,
            width: "13rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setDateDelieveredInitial("");
                        setDateDeliveredFinal("");
                        getOrders({ DateDeliveredInital: "", DateDeliveredFinal: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar fecha de entrega
                    <div
                        style={{
                            flexDirection: "row",
                            width: "100%",
                            display: "flex",
                            gap: "10px"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            Después de
                            <DatePicker
                                value={dateDeliveredInital}
                                onChange={setDateDelieveredInitial}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            Antes de
                            <DatePicker
                                value={dataDeliveredFinal}
                                onChange={setDateDeliveredFinal}
                            />
                        </div>
                    </div>
                    
                </Filter>
            ),
            render: (value, record) => (
                <DatePicker
                    value={value}
                    onChange={(val) => updateOrderDeliveredDate(record.id, val)}
                />
            )
        },
        {
            title: "Productos",
            dataIndex: 'products',
            key: 'products',
            ellipsis: false,
            width: "16rem",
            filterDropdown: () => (
                <Filter
                    onCancel={() => {
                        setProductIds("");
                        getOrders({ ProductIds: "" });
                    }}
                    onConfirm={() => getOrders()}
                >
                    Filtrar Estado
                    <Select
                        value={productIds}
                        onChange={(e) => setProductIds(e.target.value)}
                    >
                        <option value="" disabled>Producto</option>
                        {products.map((product) => 
                            <option value={product.id}>{product.name}</option>
                        )}
                    </Select>
                </Filter>
            )
        },
        {
            title: "Venta total",
            dataIndex: 'total_price',
            key: 'total_price',
            ellipsis: true,
            width: "7rem",
        },
        {
            title: "Método de pago",
            dataIndex: 'receipt_url',
            key: 'receipt_rul',
            ellipsis: true,
            width: "13rem",
            render: (val: string, record) => val ? <a href={val}>Comprobante</a> : (
                <Popover
                    open={stripeId === record.stripe_id}
                    onOpenChange={(val) => val ? setStripeId(record.stripe_id) : setStripeId("")}
                    trigger="click"
                    content={<PaymentMethodPopover stripeId={stripeId} />}
                    title="Tarjeta"
                    placement="bottom"
                >
                    <div style={{ cursor: "pointer", textDecoration: "underline", color: "blue"}}>
                        Tarjeta
                    </div>
                </Popover>
            ),
        }
    ];

    return (
        <Container>
            <Table
                columns={columns}
                dataSource={orders}
                bordered
                pagination={{
                    onChange: (page) => {
                        setCurrentPage(page);
                        getOrders({ CurrentPage: page });
                    },
                    total: totalOrders,
                    pageSize: perPage,
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) =>  {
                        setPerPage(size);
                        getOrders({ PerPage: size });
                    },
                }}
            />
        </Container>
    );
};

export default Orders;
