import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import server from "../../services/server.tsx";
import { AppContext } from "../../services/context.tsx";
import { SupplierToEdit, SupplierStates, City, SupplierType } from "./Suppliers.d";
import EditSuppliersModal from "./EditSuppliersModal.tsx";
import { message } from "antd";
import joggigImage from "../../resources/img/jogging.png";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import ConfirmDeleteCityModal from "./ConfirmDeleteCity.tsx";
import ConfirmDeleteStateModal from "./ConfirmDeletState.tsx";
import ConfirmDeleteSupplierModal from "./ConfirmDeleteSupplier.tsx";

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 2px;
    &:hover{
        background-color: #d3d3d3;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin: 0px 100px;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 40px;
    font-size: 16px;
    width: 200px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: fit-content;
`;

const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    gap: 10px;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #0d4d21;
`;

const StateGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(33% - 10px);
    min-width: 300px;
    gap: 20px;
    font-size: 20px;
    color: #b1c904;
    font-weight: bold;
`;

const CityGroup = styled.div`
    font-size: 18px;
    color: #0d4d21;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
`;

const SupplierGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 10px;
    color: black;
    font-weight: normal;
    font-size: 14px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const Suppliers: React.FC = () => {
    const { auth } = useContext(AppContext);
    const [supplierToEdit, setSupplierToEdit] = useState<SupplierToEdit | null>(null);
    const [supplierStates, setSupplierStates] = useState<SupplierStates[]>([]);
    const [stateToDelete, setStateToDelete] = useState<SupplierStates | null>(null);
    const [cityToDelete, setCityToDelete] = useState<City | null>(null);
    const [supplierToDelete, setSupplierToDelete] = useState<SupplierType | null>(null);
    const defaultSupplier = {
        name: "",
        address: "",
        phone_number: "",
        city: "",
        state: "",
        id: 0,
    };

    const getSuppliers = async () => {
        const res = await server("Suppliers-gs");
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            setSupplierStates(res.data);
        }
    }

    useEffect(() => {
        getSuppliers();
    }, []);

    return (
        <Container>
            {!!supplierToEdit && (
                <EditSuppliersModal
                    reload={getSuppliers}
                    closeModal={() => setSupplierToEdit(null)}
                    supplier={supplierToEdit}
                />
            )}
            {!!supplierToDelete && (
                <ConfirmDeleteSupplierModal 
                    reload={getSuppliers}
                    closeModal={() => setSupplierToDelete(null)}
                    supplier={supplierToDelete}
                />
            )}
            {!!cityToDelete && (
                <ConfirmDeleteCityModal 
                    reload={getSuppliers}
                    closeModal={() => setCityToDelete(null)}
                    city={cityToDelete}
                />
            )}
            {!!stateToDelete && (
                <ConfirmDeleteStateModal 
                    reload={getSuppliers}
                    closeModal={() => setStateToDelete(null)}
                    state={stateToDelete}
                />
            )}
            {auth ? (
                <Button onClick={() => setSupplierToEdit(defaultSupplier)}>
                    Agregar distribuidor
                </Button>
            ) : (
                <HeaderContainer>
                    <img
                        src={joggigImage}
                        alt="run"
                        style={{
                            width: "40%",
                            height: "auto",
                        }}
                    />
                    <Title>
                        Conoce dónde más puedes encontrar herbopro
                    </Title>
                </HeaderContainer>
            )}
            <ContentRow>
                {supplierStates.map((state) => (
                    <StateGroup key={state.id}>
                        <Row>
                            {state.name}
                            {auth && (
                                <IconContainer onClick={() => setStateToDelete(state)}>
                                    <FaTrash size={16} color="red" />
                                </IconContainer>
                            )}
                        </Row>
                        {state.cities.map((city) => (
                            <CityGroup key={city.id}>
                                <Row>
                                    {city.name}
                                    {auth && (
                                        <IconContainer onClick={() => setCityToDelete(city)}>
                                            <FaTrash size={16} color="red" />
                                        </IconContainer>
                                    )}
                                </Row>
                                {city.suppliers.map((supplier) => (
                                    <SupplierGroup>
                                        <div>
                                            <Row>
                                                <b>{supplier.name}</b>
                                                {auth && (
                                                    <>
                                                        <IconContainer onClick={() => setSupplierToEdit({
                                                            ...supplier,
                                                            city: city.name,
                                                            state: state.name,
                                                        })}>
                                                            <MdEdit size={20} color="#0d4d21" />
                                                        </IconContainer>
                                                        <IconContainer onClick={() => setSupplierToDelete(supplier)}>
                                                            <FaTrash size={16} color="red" />
                                                        </IconContainer>
                                                    </>
                                                )}
                                            </Row>
                                            {`${supplier.address} Tel. ${supplier.phone_number}`}
                                        </div>
                                    </SupplierGroup>
                                ))}
                            </CityGroup>
                        ))}
                    </StateGroup>
                ))}
            </ContentRow>
        </Container>
    );
};

export default Suppliers;