import React, { useState, useRef } from "react";
import Modal from "../../components/Modal.tsx";
import styled from "styled-components";
import { ProductType } from "./Products.d";
import { uploadFile, deleteFile } from "../../services/files.tsx";
import { message } from "antd";
import { Input as AntInput } from "antd";
import server from "../../services/server.tsx";

interface EditProductsModalProps {
    product: ProductType;
    closeModal: () => void;
    reload: () => void;
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex: direction: row;
    gap: 20px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const SideContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 300px;
`;

const Button = styled.button`
    border-radius: 16px;
    height: 32px;
    background-image: linear-gradient(#edcf76, #c7d67e);
    font-weight: bold;
    color: #0d4d21;
    cursor: pointer;
    &:hover {
        border: 1px solid #0d4d21;
    }
    border: none;
    padding: 0px 10px;
    width: fit-content;
`;

const Input = styled.input`
    width: 275px;
    border-radius: 6px;
    height: 30px;
    padding: 0 10px;
    border-color: black;
`;

const TextArea = styled(AntInput.TextArea)`
    width: 100%;
    border-radius: 6px;
    min-height: 32px;
    border-color: black;
    color: black;
    &:focus-visible {
        outline: none;
    }
`;

const PriceRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
`;

const ButtonRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
`;

const EditProductsModal: React.FC<EditProductsModalProps> = ({
    product,
    closeModal,
    reload,
}) => {
    const [name, setName] = useState<string>(product.name);
    const [imageUrl, setImageUrl] = useState<string>(product.image_url);
    const [description, setDescription] = useState<string>(product.description);
    const [price, setPrice] = useState<number>(product.price);
    const ref = useRef<HTMLInputElement>(null);

    const onUploadFile = async ({ target }: any) => {
        if (target.files && target.files.length > 0) {
            const file = target.files[0];
            let cleanName = file.name.replace(/[^a-zA-Z0-9.]/g, "");
            const fileUrl = await uploadFile(file, cleanName, "Products");
            if (fileUrl.includes("Error|")) {
                message.error(fileUrl.replace("Error|", ""));
                return;
            }
            if (!!imageUrl && imageUrl !== product.image_url) {
                await deleteFile(imageUrl);
            }
            setImageUrl(fileUrl);
        }   
    };

    const onCloseModal = async () => {
        if (!!imageUrl && imageUrl !== product.image_url) {
            await deleteFile(imageUrl);
        }
        closeModal();
    };

    const onSubmit = async () => {
        const res = await server("Products-rp", {
            Id: product.id,
            PhotoUrl: imageUrl,
            Name: name,
            Description: description,
            Price: price,
        });
        if (res.error_message) {
            message.error(res.error_message);
        } else {
            message.success(res.message);
            reload();
            closeModal();
        }
    };

    return (
        <Modal
            open
            closeModal={onCloseModal}
            title="Editar producto"
            width="700px"
        >
            <Content>
                <Container>
                    <SideContainer>
                        {!!imageUrl && (
                            <img
                                src={imageUrl}
                                alt="product"
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    borderRadius: "100px",
                                }}
                            />
                        )}
                        <input
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            ref={ref}
                            onChange={onUploadFile}
                        />
                        <Button onClick={() => ref?.current?.click()}>
                            Seleccionar imagen
                        </Button>
                    </SideContainer>
                    <SideContainer style={{ alignItems: "flex-start" }}>
                        <Input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Nombre del producto"
                        />
                        <PriceRow>
                            Precio
                            <Input
                                style={{ width: "100px" }}
                                value={price}
                                onChange={(e) => setPrice(Number(e.target.value))}
                                type="number"
                            />
                            MXN
                        </PriceRow>
                        <TextArea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Descripción del producto"
                            autoSize
                        />
                    </SideContainer>
                </Container>
                <ButtonRow>
                    <Button style={{ background: "white" }} onClick={onCloseModal}>
                        Cancelar
                    </Button>
                    <Button onClick={onSubmit}>
                        Guardar
                    </Button>
                </ButtonRow>
            </Content>
        </Modal>
    );
};

export default EditProductsModal;
