import React, { useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import rectangle from "../resources/img/rectangle.svg";
import greenStripe from "../resources/img/linea-verde.svg";
import whatsAppLogo from "../resources/img/Whatsap.svg";
import facebookLogo from "../resources/img/facebook.svg";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Start from "../views/Start.tsx";
import Login from "../views/Login.tsx";
import Products from "../views/Products/Products.tsx";
import Buy from "../views/Buy.tsx";
import { AppContext } from "../services/context.tsx";
import Suppliers from "../views/Suppliers/Suppliers.tsx";
import Orders from "../views/Orders/Orders.tsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "../services/constants.tsx";

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;


const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    gap: 2rem;
    height: fit-content;
    max-height: calc(100vh - 215px);
`;

const Footer = styled.div`
    flex: 1;
    display: flex;
    overlow: hidden;
    background-image: url(${rectangle});
    color: #004d21;
    max-height: 185px;
    font-weight: bold;
    flex-direction: column;
`;

const Info = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    padding: 0px 100px;
    margin-top: 30px;
`;

const InfoContainer = styled.div`
    flex-direction: column;
    display: flex;
    gap: 5px;
`;

const NavBar = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    color: #000;
    gap: 100px;
    height: 50px;
    font-weight: bold;
    padding-left: 100px;
    margin-top: 2rem;
`;

const NavItem = styled.div`
    cursor: pointer;
`;

const stripePromise = loadStripe(stripeKey || "");

const MainPageLayout: React.FC = () => {
    const { setAuth, auth } = useContext(AppContext);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const auth = localStorage.getItem("auth");
        setAuth(!!auth);
        window.addEventListener("remove", () => {
            setAuth(false);
            localStorage.setItem("auth", "");
        })
        //eslint-disable-next-line
    }, []);

    const logout = () => {
        setAuth(false);
        localStorage.setItem("auth", "");
    };

    return (
        <Elements stripe={stripePromise}>
            <Container>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <NavBar>
                        {!auth ? (
                            <Link
                                style={{ fontWeight: "bold", color: "black", textDecoration: "none" }}
                                to="/home/start"
                            >
                                Inicio
                            </Link>
                        ) : (
                            <Link
                                style={{ fontWeight: "bold", color: "black", textDecoration: "none" }}
                                to="/home/orders"
                            >
                                Órdenes
                            </Link>
                        )}
                        <Link
                            style={{ fontWeight: "bold", color: "black", textDecoration: "none" }}
                            to="/home/products"
                        >
                            Productos
                        </Link>
                        <Link
                            style={{ fontWeight: "bold", color: "black", textDecoration: "none" }}
                            to="/home/buy"
                        >
                            {!auth ? "Comprar" : "Registrar compra"}
                        </Link>
                        <Link
                            style={{ fontWeight: "bold", color: "black", textDecoration: "none" }}
                            to="/home/suppliers"
                        >
                            Distribuidores
                        </Link>
                        {!auth && <NavItem onClick={() => ref?.current?.scrollIntoView()}>Contáctanos</NavItem>}
                    </NavBar>
                    {auth ? (
                        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                            <div style={{ textDecoration: "underline", color: "green", fontSize: "12px", width: "100px", cursor: "pointer" }} onClick={logout}>
                                Cerrar sesión
                            </div>
                            <Link to="/home/register_user" style={{ color: "green", fontSize: "12px", width: "100px"}}>
                                Registrar usuario
                            </Link>
                        </div>
                    ) : (
                        <Link to="/home/login" style={{ color: "green", fontSize: "12px", width: "100px"}}>
                            Iniciar sesión
                        </Link>
                    )}
                </div>
                <PageContent>
                    <Routes>
                        {!auth && <Route path="/home/start" element={<Start />} />}
                        {auth && <Route path="/home/orders" element={<Orders />} />}
                        <Route path="/home/login" element={<Login />} />
                        {auth && <Route path="/home/register_user" element={<Login register />} />}
                        <Route path="/home/products" element={<Products />} />
                        <Route path="/home/buy" element={<Buy />} />
                        <Route path="/home/suppliers" element={<Suppliers />} />
                        <Route path="*" element={<Navigate to={auth ? "/home/orders" : "/home/start"} replace />}/>
                    </Routes>
                    {!auth && (
                        <Footer ref={ref}>
                            <Info>
                                <InfoContainer>
                                    <div>Contáctanos</div>
                                    <div>contacto@herbopro.com</div>
                                    <img
                                        alt="whatsapp"
                                        src={whatsAppLogo}
                                        onClick={() => window.open("https://wa.me/523316580763")}
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </InfoContainer>
                                <InfoContainer>
                                    <div>Síguenos en redes</div>
                                    <img
                                        alt="facebook"
                                        src={facebookLogo}
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => window.open("https://www.facebook.com/herbopro")}
                                    />
                                </InfoContainer>
                            </Info>
                            <img
                                style={{ width: "100%", display: "flex" }}
                                src={greenStripe}
                                alt="green"
                            />
                        </Footer>
                    )}
                </PageContent>
            </Container>
        </Elements>
    );
};

export default MainPageLayout;