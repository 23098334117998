import React from "react";
import styled from "styled-components";
import { Modal as AntModal } from "antd";
import { IoClose } from "react-icons/io5";
import { ModalProps } from "antd";

const StyledModal = styled(AntModal)`
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
    margin-bottom: 20px;
    font-weight: bold;
`;

const IconContainer = styled.div`
    display: flex;
    align-itmes: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 2px;
    &:hover{
        background-color: #d3d3d3;
    }
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    width: 100%;
`;

interface AppModalProps extends ModalProps {
    closeModal: () => void;
    title: string;
}

const Modal: React.FC<AppModalProps> = ({
    closeModal,
    title,
    children,
    ...props
}) => (
    <StyledModal
        onCancel={closeModal}
        footer={null}
        closable={false}
        centered
        styles={{
            body: {
                maxHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                padding: "0px 12px",
            }
        }}
        {...props}
    >
        <Header>
            <div>{title}</div>
            <IconContainer onClick={closeModal}>
                <IoClose size={20} />
            </IconContainer>
        </Header>
        <Content>
            {children}
        </Content>
    </StyledModal>
);

export default Modal;
